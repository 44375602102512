body {
  font-family: century-gothic;
  background-color: #242e3b;
}

.App {
  font-family: century-gothic;
  background-color: rgb(48, 50, 52);
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: hidden;
}

/* TODO  remove when react-error-overlay gets a fix in the future */
body > iframe {
  display: none;
}
